<!-- eslint-disable max-len -->

<template>
<div v-if="role.isG">
  <section class="hero pt-5 mt-4">
  </section>
  <div class="container-fluid mt-5 pt-1">
    <div class="row px-xl-3">
      <div class="col-sm-4 col-md-2 col-lg-2" style="background-color: #fff;">
        <div style="top: 120px;" class="sticky-top">
          <div class="sidebar-block" id="guest-accordion" role="tablist">
            <div @click="showGuestSideNav" class="block-header d-flex justify-content-between align-items-center">
              <strong><a :href="`#${hash}`" class="accordion-link">Get Started</a></strong>
              <i class="fas text-right" :class="showGuestMenu ? 'fa-angle-up' : 'fa-angle-down'"></i>
            </div>
            <transition name="toggle">
              <div v-if="showGuestMenu" class="collapse show" @click="scrollUp">
                <nav class="nav nav-pills flex-column mb-0 pb-0">
                  <div v-for="(c,i) in comps" :key="i">
                    <!-- <a @click="comp=c.name" :href="`#${c.tab.toLowerCase()}`" class="nav-link" :class="comp==c.name? 'active':''"> {{c.tab}} <span v-if="c.new" class="badge badge-warning ml-1">New</span></a> -->
                    <router-link :to="{ name: `guest-${c.tab.toLowerCase()}`}" class="nav-link"> {{c.tab}} <span v-if="c.new" class="badge badge-warning ml-1">New</span></router-link>
                    <div v-if="i===1" class="dropdown-divider my-1"></div>
                  </div>
                </nav>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="col tab-content">
        <!-- <keep-alive>
          <component v-bind:is="comp" />
        </keep-alive> -->
        <router-view name="guest" v-slot="{Component}">
          <transition name="fade">
            <component :is="Component"/>
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex';
// import GuestProfile from '../../components/Guests/GuestProfile.vue';
// import EWallet from '../../components/Guests/E-Wallet.vue';
// import UpcomingWeddings from '../../components/Guests/UpcomingWeddings.vue';
// import ContributionsGiven from '../../components/Guests/ContributionsGiven.vue';
// import EInvites from '../../components/Guests/E-Invites.vue';
// import GiftsGiven from '../../components/Guests/GiftsGiven.vue';

export default {
  name: 'guest-account',
  components: {
    // GuestProfile, EWallet, UpcomingWeddings, ContributionsGiven, EInvites, GiftsGiven,
  },
  data: () => ({
    comp: 'GuestProfile',
    hash: 'profile',
    comps: [
      { name: 'GuestProfile', tab: 'Profile', new: false },
      { name: 'EWallet', tab: 'Wallet', new: false },
      // { name: 'UpcomingWeddings', tab: 'Upcoming', new: true },
      { name: 'ContributionsGiven', tab: 'Contributions', new: false },
      // { name: 'EInvites', tab: 'Invites', new: false },
      // { name: 'GiftsGiven', tab: 'Gifts', new: false },
    ],
    showGuestMenu: true,
  }),
  created() {
    // this.$store.dispatch('setUserRole');
  },
  methods: {
    showGuestSideNav() {
      this.showGuestMenu = !this.showGuestMenu;
    },
    scrollUp() {
      // this.$store.dispatch('smoothScroll');
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.startMenu();
    },
    startMenu() {
      if (window.screen.width < 768) {
        setTimeout(() => {
          this.showGuestMenu = false;
        }, 100);
      }
      // this.showGuestMenu = false;
    },
  },
  computed: {
    ...mapState(['role']),
  },
};
</script>
<style scoped>
.router-link-active, .router-link-exact-active {
  background-color: #e9ecef;
}
.fade-enter-active, .fade-leave-active {
  transition: all 0s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
  /* transform: translateY(-3px); */
  opacity: 0;
}
.toggle-enter-active, .toggle-leave-active {
  transition: all 0s ease-in-out;
}
.toggle-enter-from, .toggle-leave-to {
  transform: translateY(-12px);
  opacity: 0;
}
</style>
